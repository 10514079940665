import React from 'react';
import styled from 'styled-components';
import { ReactComponent as EyeIcon } from '../assets/eye.svg';
import { ReactComponent as TargetIcon } from '../assets/greenlogo.svg';
import Navbar from './NavigationLanding';
import Footer from './Footer';

const Container = styled.div`
  height: auto;
  background: black;
`;

const PricingGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
  text-align: center;
  max-width: 800px;
  margin: 0 auto;
  padding: 40px;
  background: black;
  padding-top: 70px;

  @media (max-width: 1024px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
    padding: 70px;
  }
`;

const PricingCard = styled.div`
  background: black;
  border: 2px solid #e5e5e5;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  padding: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 20px;
  min-height: 550px;
  margin-left: 25px;
  margin-right: 25px;

  @media (max-width: 768px) {
    min-height: auto;
    margin-left: 0;
    margin-right: 0;
  }
`;

const PricingCardGreen = styled(PricingCard)`
  border: 4px solid #2c6b5f;
`;

const PlanName = styled.h3`
  font-size: 2rem;
  font-weight: bold;
  margin-bottom: 40px;
  color: white;
  font: Inter, sans-serif;

  @media (max-width: 768px) {
    font-size: 1.8rem;
    margin-bottom: 20px;
  }
`;

const PlanNameGreen = styled(PlanName)`
  color: #2c6b5f;
`;

const PlanIcon = styled.div`
  height: 120px;
  width: 120px;
  margin-bottom: 90px;

  @media (max-width: 768px) {
    height: 80px;
    width: 80px;
    margin-bottom: 40px;
  }

  svg {
    height: 100%;
    width: 100%;
  }
`;

const PlanFeatures = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0 0 30px;
  
  text-align: center;
  color: white;
  font: Inter, sans-serif;
  font-weight: 500;

  li {
    margin-bottom: 8px;
    font-size: 1.5rem;

    @media (max-width: 768px) {
      font-size: 1.2rem;
    }
  }
`;

const PlanFeaturesGreen = styled(PlanFeatures)`
  color: #2c6b5f;
`;



const ContactUs = styled.div`
  font-size: 2rem;
  font-weight: bold;
  color: white;
  margin-top: auto;

  @media (max-width: 768px) {
    font-size: 1.8rem;
  }
`;

const FreeText = styled.div`
  font-size: 2rem;
  font-weight: bold;
  color: #174b42;
  margin-top: auto;

  @media (max-width: 768px) {
    font-size: 1.8rem;
  }
`;

const FormTitle = styled.h2`
  color: #174b42;
  text-align: center;
  padding-top: 50px;
  margin-bottom: 0px;
  font-size: 3rem;
  font: 700 3rem 'Inter', sans-serif;
  margin-top: 0;

  @media (max-width: 768px) {
    font-size: 2.5rem;
    padding-top: 30px;
  }
`;

const Pricing = () => {
  return (
    <>
      <Navbar />
      <Container>
        <FormTitle>Pricing</FormTitle>
      <PricingGrid>
        
        <PricingCard>
          <PlanName>Chat + Analytics</PlanName>
          <PlanIcon>
            <EyeIcon />
          </PlanIcon>
          <PlanFeatures>
            <li>-All chat features</li><br></br>
            <li>-Advanced analytics</li><br></br>
            <li>-Chat with your data</li>
          </PlanFeatures>
          <ContactUs>Contact Us</ContactUs>
        </PricingCard>
        <PricingCardGreen>
          <PlanNameGreen>Other Features</PlanNameGreen>
          <PlanIcon>
            <TargetIcon />
          </PlanIcon>
          <PlanFeaturesGreen>
            <li>-Constantly adding the latest innovations</li><br></br>
            <li>-Custom features made for your pharmacy</li>
          </PlanFeaturesGreen>
          <FreeText>Free</FreeText>
        </PricingCardGreen>
      </PricingGrid>
        </Container>
        <Footer />
    </>
  );
};

export default Pricing;
import React from 'react'
import Navbar from './NavigationLanding'
import Hero from './Hero'



// ... (rest of the CSS remains the same)
export default function Home() {
  return (
    <>
         <Navbar />
             <Hero />
    </>
  )
}

import React, { useState } from 'react';
import styled from 'styled-components';
import Navbar from './NavigationLoggedIn';
import TabbedCart from './TabbedCart';

const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
  overflow: hidden;
`;

const ContentContainer = styled.div`
  flex: 1;
  background-color: #000000;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 20px;
  padding-left: 50px;
`;

const SearchContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
`;

const SearchBox = styled.input`
  width: 400px;
  height: 30px;
  padding: 5px;
  font-size: 16px;
  font-family: 'Inter', sans-serif;
  color: #ffffff;
  border: 1px solid #ffffff;
  background-color: #000000;
  margin-right: 10px;
`;

const Button = styled.button`
  width: 100px;
  height: 40px;
  background-color: #000000;
  color: #ffffff;
  border: 1px solid #ffffff;
  margin-right: 10px;
  cursor: pointer;
`;

const DataFrame = styled.table`
  width: 100%;
  border-collapse: collapse;
  color: #ffffff;
  border: 1px solid #ffffff;
  transition: flex 0.3s ease-in-out;
`;

const TableHeader = styled.th`
  padding: 10px;
  text-align: left;
  border-bottom: 1px solid #ffffff;
  background-color: #2c6b5f;
`;

const TableData = styled.td`
  padding: 10px;
  border-bottom: 1px solid #ffffff;
  cursor: pointer;
`;

const ItemDetailsContainer = styled.div`
  flex: 1;
  background-color: #000000;
  border: 1px solid #ffffff;
  padding: 20px;
  margin-left: 20px;
  color: #ffffff;
`;

const BackButton = styled.button`
  background-color: transparent;
  color: #ffffff;
  border: none;
  font-size: 24px;
  cursor: pointer;
  margin-bottom: 10px;
`;

const Search = () => {
    const [searchTerm, setSearchTerm] = useState('');
    const [selectedItem, setSelectedItem] = useState(null);
  
    // Mock data for the data frame
    const mockData = [
      { name: 'John Doe', invoice: 'INV001', reference: 'REF001', lastPurchase: '2023-05-01' },
      { name: 'Jane Smith', invoice: 'INV002', reference: 'REF002', lastPurchase: '2023-04-15' },
      { name: 'Mike Johnson', invoice: 'INV003', reference: 'REF003', lastPurchase: '2023-05-10' },
    ];
  
    const handleSearch = () => {
      // Perform search logic here based on the searchTerm
      console.log('Searching for:', searchTerm);
    };
  
    const handleBack = () => {
      setSelectedItem(null);
    };
  
    return (
      <PageContainer>
        <Navbar />
        <ContentContainer>
          <SearchContainer>
            <SearchBox
              type="text"
              placeholder="Search..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
            <Button onClick={handleSearch}>Search</Button>
            <Button>Button 1</Button>
            <Button>Button 2</Button>
          </SearchContainer>
          <div style={{ display: 'flex', width: '100%' }}>
            <DataFrame style={{ flex: selectedItem ? '0 0 200px' : '1' }}>
              <thead>
                <tr>
                  <TableHeader>Name</TableHeader>
                  {!selectedItem && (
                    <>
                      <TableHeader>Invoice</TableHeader>
                      <TableHeader>Reference</TableHeader>
                      <TableHeader>Last Purchase</TableHeader>
                    </>
                  )}
                </tr>
              </thead>
              <tbody>
                {mockData.map((item, index) => (
                  <tr key={index} onClick={() => setSelectedItem(item)}>
                    <TableData>{item.name}</TableData>
                    {!selectedItem && (
                      <>
                        <TableData>{item.invoice}</TableData>
                        <TableData>{item.reference}</TableData>
                        <TableData>{item.lastPurchase}</TableData>
                      </>
                    )}
                  </tr>
                ))}
              </tbody>
            </DataFrame>
            {selectedItem && (
              <ItemDetailsContainer>
                <BackButton onClick={handleBack}>&larr;</BackButton>
                <h2>{selectedItem.name}</h2>
                <p>Invoice: {selectedItem.invoice}</p>
                <p>Reference: {selectedItem.reference}</p>
                <p>Last Purchase: {selectedItem.lastPurchase}</p>
              </ItemDetailsContainer>
            )}
          </div>
        </ContentContainer>
        <TabbedCart />
      </PageContainer>
    );
  };

  export default Search;
import React, { useState } from 'react';
import styled from 'styled-components';



const TabsContainer = styled.div`
  display: flex;
  background-color: #000000;
  padding: 0px 100px;
  position: relative;
`;

const Tab = styled.div`
  background-color: ${({ active }) => (active ? '#174b42' : '#15171c')};
  color: ${({ active }) => (active ? '#FFFFFF' : '#FFFFFF')};
  padding: 5px 30px;
  cursor: pointer;
  margin-right: 10px;
  transition: background-color 0.3s ease;
  position: relative;
  bottom: 0px;
  clip-path: polygon(10% 0%, 90% 0%, 100% 100%, 0% 100%);

  &:not(.active):before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    clip-path: polygon(10% 0%, 90% 0%, 100% 100%, 0% 100%);
    pointer-events: none;
  }
`;

const MinimizeTab = styled(Tab)`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ArrowDown = styled.div`
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid ${({ active }) => (active ? '#000000' : '#FFFFFF')};
  margin: 0 -5px; // Adjust the margin to reduce side padding
`;

const WhiteLine = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 1px;
  background-color: #FFFFFF;
`;

const TabContent = styled.div`
  background-color: #000000;
  color: #FFFFFF;
  padding: 20px;
  width: 100%;
  height: ${({ minimized, expanded }) => (minimized ? '0' : expanded ? '90%' : '25%')};
  transition: height 0.3s ease;
  overflow: hidden;
`;

const TabbedCartContainer = styled.div`
  position: fixed;
  bottom: 0;
  width: 100%;
  height: ${({ expanded, minimized }) =>
    minimized ? '10vh' : expanded ? '40vh' : '25vh'};
  transition: height 0.3s ease;
`;

const TabbedCart = () => {
  const [activeTab, setActiveTab] = useState('KINRAY');
  const [minimized, setMinimized] = useState(false);
  const [expanded, setExpanded] = useState(false);

  const handleTabClick = (tab) => {
    setActiveTab(tab);
    setMinimized(false);
    setExpanded(true);
  };

  const handleMinimizeClick = () => {
    setMinimized(!minimized);
    setExpanded(false);
  };

  return (
    <TabbedCartContainer expanded={expanded} minimized={minimized}>
      <TabsContainer>
        <MinimizeTab active={minimized} onClick={handleMinimizeClick}>
          <ArrowDown active={minimized} />
        </MinimizeTab>
        <Tab active={activeTab === 'KINRAY'} onClick={() => handleTabClick('KINRAY')}>
          KINRAY
        </Tab>
        <Tab active={activeTab === 'DENCO'} onClick={() => handleTabClick('DENCO')}>
          DENCO
        </Tab>
        <WhiteLine />
      </TabsContainer>
      <TabContent minimized={minimized} expanded={expanded}>
        {activeTab === 'KINRAY' ? (
          <div>KINRAY Cart Content</div>
        ) : (
          <div>DENCO Cart Content</div>
        )}
      </TabContent>
    </TabbedCartContainer>
  );
};

export default TabbedCart;

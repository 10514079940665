import React from 'react';
import styled from 'styled-components';
import Navbar from './NavigationLanding';
import Footer from './Footer';

const ThankYouContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: black;
  flex-direction: column;
`;

const ThankYouMessage = styled.h2`
  color: white;
  text-align: center;
  font-size: 3rem;
  font: 700 3rem 'Inter', sans-serif;
  margin-bottom: 0.5rem; /* Adjust this value to reduce spacing */
`;

const ReachOutMessage = styled.h2`
  color: white;
  text-align: center;
  font: 700 2rem 'Inter', sans-serif;
  margin-top: 0; /* Adjust this value to reduce spacing */
`;

const ThankYou = () => {
  return (
    <>
      <Navbar />
      <ThankYouContainer>
        <ThankYouMessage>Thank you for signing up!</ThankYouMessage>
        <ReachOutMessage>We will reach out to you soon.</ReachOutMessage>
      </ThankYouContainer>
      <Footer />
    </>
  );
};

export default ThankYou;

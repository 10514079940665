import React, { useState } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { ReactComponent as LogoSVG } from '../assets/logo.svg';
import { FaBars, FaTimes } from 'react-icons/fa';

// Define breakpoints for responsive design
const breakpoints = {
  mobile: '768px',
};

// Helper function for media queries
const media = {
  mobile: `(max-width: ${breakpoints.mobile})`,
};

// Styles for Navbar and related components
const Nav = styled.nav`
  background: #000;
  height: 80px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 30px;
  @media ${media.mobile} {
    height: auto;
    padding: 10px;
    flex-direction: column;
  }
`;

const Logo = styled(LogoSVG)`
  width: auto;
  height: 40px;
  margin-right: 20px;
  @media ${media.mobile} {
    height: 30px;
    margin-right: 0;
    margin-bottom: 10px;
  }
`;

const Button = styled(Link)`
  font-family: 'Inter', sans-serif;
  background-color: #174b42;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1.2rem;
  font-weight: normal;
  margin-top: -10px;
  text-decoration: none; /* Remove the underline */
  &:hover {
    background-color: #2c6b5f;
  }
  @media ${media.mobile} {
    width: 50%;
    margin-top: 10px;
    align-self: center;
  }
`;

const LogoLink = styled(Link)`
  width: auto;
  height: 40px;
  @media ${media.mobile} {
    height: 30px;
  }
  & > svg {
    height: 100%;
  }
`;

const Hamburger = styled.div`
  display: none;
  cursor: pointer;
  @media ${media.mobile} {
    display: block;
    font-size: 24px;
    color: #fff;
  }
`;

const NavigationLoggedIn = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <Nav>
      <LogoLink to="/">
        <Logo />
      </LogoLink>
      <Hamburger onClick={toggleMenu}>
        {isOpen ? <FaTimes /> : <FaBars />}
      </Hamburger>
      <Button to="/logout">Log out</Button>
    </Nav>
  );
};

export default NavigationLoggedIn;
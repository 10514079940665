import React, { useState } from 'react';
import styled from 'styled-components';
import Navbar from './NavigationLanding';
import Footer from './Footer';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

const FormContainer = styled.div`
  display: flex;
  justify-content: center;
  height: 100vh;
  background-color: black;
`;

const FormWrapper = styled.div`
  background-color: black;
  padding: 40px;
  border-radius: 5px;
  width: 400px;
  align-items: center;
`;

const FormTitle = styled.h2`
  color: white;
  text-align: center;
  margin-bottom: 30px;
  font-size: 3rem;
  font: 700 3rem 'Inter', sans-serif;
  padding-bottom: 80px;
`;

const FormInput = styled.input`
  width: 100%;
  padding: 12px;
  margin-bottom: 30px;
  border: none;
  border-radius: 10px;
  background-color: black;
  border: 1px solid white;
  color: white;
  font: 400 1rem 'Inter', sans-serif;
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;
`;

const SubmitButton = styled.button`
  width: 120px;
  background-color: #174b42;
  color: white;
  padding: 12px;
  border: none;
  border-radius: 3px;
  cursor: pointer;
  font: 500 1rem 'Inter', sans-serif;
`;

const Login = () => {
  const navigate = useNavigate();

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    // Handle form submission logic here
    console.log('Form submitted:', { email, password });

    try {
      await login();
      navigate('/dashboard');
    } catch (error) {
      console.error('Login failed:', error);
      // Optionally, display an error message to the user
    }
  };

  const login = async () => {
    const loginUrl = 'https://your-api-endpoint/login';
    const loginData = {
      email: email,
      password: password,
    };

    try {
      console.log('Logging in with data:', loginData);
      const response = await axios.post(loginUrl, loginData, {
        headers: {
          'Content-Type': 'application/json',
        },
      });

      if (response.status !== 200) {
        throw new Error('Network response was not ok');
      }
      console.log('Login successful');
    } catch (error) {
      console.error('Error logging in:', error);
    }
  };

  return (
    <>
      <Navbar />
      <FormContainer>
        <FormWrapper>
          <FormTitle>Login</FormTitle>
          <form onSubmit={handleSubmit}>
            <FormInput
              type="email"
              placeholder="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
            <FormInput
              type="password"
              placeholder="Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
            <ButtonWrapper>
              <SubmitButton type="submit">Login</SubmitButton>
            </ButtonWrapper>
          </form>
        </FormWrapper>
      </FormContainer>
      <Footer />
    </>
  );
};

export default Login;

import React, { useState } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { ReactComponent as LogoSVG } from '../assets/logo.svg';
import { FaBars, FaTimes } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';

// Define breakpoints for responsive design
const breakpoints = {
  mobile: '768px',
};

// Helper function for media queries
const media = {
  mobile: `(max-width: ${breakpoints.mobile})`,
};

// Styles for Navbar and related components
const Nav = styled.nav`
  background: #000;
  height: 80px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 30px;
  @media ${media.mobile} {
    height: auto;
    padding: 10px;
    flex-direction: column;
  }
`;

const Logo = styled(LogoSVG)`
  width: auto;
  height: 40px;
  margin-right: 20px;
  @media ${media.mobile} {
    height: 30px;
    margin-right: 0;
    margin-bottom: 10px;
  }
`;

const NavItems = styled.div`
  display: flex;
  @media ${media.mobile} {
    display: ${({ isOpen }) => (isOpen ? 'flex' : 'none')};
    flex-direction: column;
    align-items: center; /* Center items horizontally */
    width: 100%;
  }
`;

const NavLink = styled.a`
  font-family: 'Inter', sans-serif;
  color: #fff;
  text-decoration: none;
  font-weight: 500;
  font-size: 1.2rem;
  line-height: 26px;
  margin: 0 30px;
  display: inline-block;
  &:hover {
    color: #666;
  }
  @media ${media.mobile} {
    margin: 10px 0;
    width: 100%;
    text-align: center; /* Center text */
  }
`;

const Button = styled.button`
  font-family: 'Inter', sans-serif;
  background-color: #174b42;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1.2rem;
  font-weight: normal;
  margin-top: -10px;
  &:hover {
    background-color: #2c6b5f;
  }
  @media ${media.mobile} {
    width: 50%;
    margin-top: 10px;
    align-self: center;
  }
`;

const AuthLinks = styled.div`
  display: flex;
  margin-top: 10px;
  @media ${media.mobile} {
    display: ${({ isOpen }) => (isOpen ? 'flex' : 'none')};
    flex-direction: column;
    align-items: center; /* Center items horizontally */
    width: 100%;
    margin-top: 0;
  }
`;

const LogoLink = styled(Link)`
  width: auto;
  height: 40px;
  @media ${media.mobile} {
    height: 30px;
  }
  & > svg {
    height: 100%;
  }
`;

const Hamburger = styled.div`
  display: none;
  cursor: pointer;
  @media ${media.mobile} {
    display: block;
    font-size: 24px;
    color: #fff;
  }
`;

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };
  let navigate = useNavigate();
  const handleButtonClick = () => {
    navigate('/signup'); // Path to the new page
  };

  return (
    <Nav>
      <LogoLink to="/">
        <Logo />
      </LogoLink>
      <Hamburger onClick={toggleMenu}>
        {isOpen ? <FaTimes /> : <FaBars />}
      </Hamburger>
      <NavItems isOpen={isOpen}>
        <NavLink as={Link} to="/pricing">Pricing</NavLink>
        <NavLink as={Link} to='/aboutus'>About Us</NavLink>
      </NavItems>
      <AuthLinks isOpen={isOpen}>
        <NavLink as={Link} to='/login'>Log in</NavLink>
        <Button onClick={handleButtonClick}>Sign Up</Button>
      </AuthLinks>
    </Nav>
  );
};

export default Navbar;

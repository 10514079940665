import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { ReactComponent as Logo } from '../assets/logo.svg';

const FooterContainer = styled.footer`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  background-color: #000;
  color: white;
  padding: 1rem;
  padding-top: 5rem;
`;

const LogoWrapper = styled.div`
  height: 40px;
  padding-left: 100px;
  
  svg {
    height: 100%;
    width: auto;
    fill: #fff;
  }
`;

const Nav = styled.nav`
  ul {
    list-style: none;
    padding-right: 100px;
    font-family: 'Inter', sans-serif;
    
    li {
      margin-bottom: 0.5rem;
      
      &:first-child {
        font-weight: bold;
        color: white;
      }
      
      &:not(:first-child) {
        color: #999;
      }
      
      &:last-child {
        margin-bottom: 0;
      }
      
      a {
        color: inherit;
        text-decoration: none;
      }
    }
  }
`;

const Footer = () => {
  return (
    <FooterContainer>
      <LogoWrapper>
        <Link to="/">
          <Logo />
        </Link>
      </LogoWrapper>
      <Nav>
        <ul>
          <li>
            <Link to="/">Menu</Link>
          </li>
          <li>
            <Link to="/pricing">Pricing</Link>
          </li>
          <li>
            <Link to="/services">Services</Link>
          </li>
          <li>
            <Link to="/about">About us</Link>
          </li>
        </ul>  
      </Nav>
    </FooterContainer>
  );
};

export default Footer;
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import MistralSVG from '../assets/mistral.svg';
import PineconeSVG from '../assets/pinecone.svg';
import AwsSVG from '../assets/aws-startups.svg';

import ChatIcon from '../assets/chat.svg';
import AnalyticsIcon from '../assets/analytics.svg';
import CartIcon from '../assets/basket.svg';
import OrderIcon from '../assets/charticon.svg';
import ChieldIcon from '../assets/Chield.svg';
import TimeIcon from '../assets/Time.svg';

import BigChart1 from '../assets/1bigchart.svg';
import BigChart2 from '../assets/2bigchart.svg';
import BigChart3 from '../assets/3bigchart.svg';
import BigChart4 from '../assets/4bigchart.svg';
import BigChart5 from '../assets/5bigchart.svg';
import BigChart6 from '../assets/6bigchart.svg';
import BigChart7 from '../assets/7bigchart.svg';

import Footer from './Footer';

// Responsive breakpoints
const breakpoints = {
  mobile: '768px',
  tablet: '1024px',
  laptop: '1366px', // New breakpoint
  desktop: '1600px',
};

const media = {
  mobile: `(max-width: ${breakpoints.mobile})`,
  tablet: `(max-width: ${breakpoints.tablet})`,
  laptop: `(max-width: ${breakpoints.laptop})`, // New media query
  desktop: `(max-width: ${breakpoints.desktop})`,
};

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  padding: 10px;
  background-color: #000;
  color: #fff;
  overflow-x: hidden;  // Ensure no horizontal overflow

  @media ${media.desktop} {
    padding: 40px 20px;
  }

  @media ${media.tablet} {
    flex-direction: column;
    align-items: center;
    padding: 20px;
  }

  @media ${media.mobile} {
    padding: 10px;
  }
`;

const TextContainer = styled.div`
  width: 50%;
  justify-content: center;
  text-align: center;
  margin-top: 80px;
  padding-left: 380px;
  padding-right: 30px;
  min-height: 400px;
  overflow-x: hidden; // Ensure no horizontal overflow

  @media ${media.desktop} {
    padding-left: 200px;
    padding-right: 0px;
  }

  @media ${media.tablet} {
    width: 100%;
    padding-left: 0;
    margin-top: 40px;
    padding-right: 0px;
    padding-top: 40px;
  }

  @media ${media.mobile} {
    padding-right: 0px;
    padding-top: 40px;
  }
`;

const Title = styled.h1`
  font-family: 'Inter', sans-serif;
  font-size: 4.8rem;
  max-width: 100%;
  text-align: left;
  padding-left: 70px;
  margin: 0;
  line-height: 1.2;
  font-weight: 700;
  letter-spacing: normal;
  overflow-x: hidden; // Ensure no horizontal overflow

  @media ${media.desktop} {
    font-size: 4rem;
    padding-left: 60px;
  }

  @media ${media.laptop} {
    font-size: 3.5rem;
    padding-left: 50px;
  }

  @media ${media.tablet} {
    text-align: center;
    padding-left: 0;
  }

  @media ${media.mobile} {
    font-size: 2.7rem;
  }
`;

const Subtitle = styled.h2`
  font-family: 'Inter', sans-serif;
  font-size: 1.5rem;
  font-weight: normal;
  text-align: left;
  padding-left: 70px;
  margin-bottom: 40px;
  max-width: 70%;
  opacity: 0.5;
  letter-spacing: 0.5px;

  @media ${media.desktop} {
    padding-left: 70px;
    text-align: left;
  }

  @media ${media.tablet} {
    max-width: 80%;
    text-align: center;
  }

  @media ${media.mobile} {
    font-size: 1.2rem;
    max-width: 100%;
    padding-left: 0;
  }
`;

const ButtonContainer = styled.div`
  text-align: left;
  padding-left: 70px;

  @media ${media.desktop} {
    padding-left: 60px;
  }

  @media ${media.tablet} {
    text-align: center;
    padding-left: 0;
  }

  @media ${media.mobile} {
    padding-left: 0;
  }
`;

const StartButton = styled.button`
  font-family: 'Inter', sans-serif;
  padding: 15px 30px;
  background-color: #174b42;
  border: none;
  color: #fff;
  font-size: 1.2rem;
  border-radius: 5px;
  cursor: pointer;
  margin-bottom: 30px;
  margin-left: 0;

  &:hover {
    background-color: #2c6b5f;
  }

  @media ${media.desktop} {
    padding: 15px 30px;
    font-size: 1rem;
  }

  @media ${media.mobile} {
    padding: 10px 20px;
    font-size: 0.9rem;
  }
`;

const ChartContainer = styled.div`
  width: 54%;
  min-height: 450px;
  min-width: 450px;
  max-width: 800px;
  border-radius: 10px;
  margin-top: 72px;
  margin-bottom: 9px;
  align-items: center;
  padding-right: 63px;

  @media ${media.desktop} {
    padding-right: 45px;
    width: 45%;
  }

  @media ${media.laptop} {
    width: 49.5%;
  }

  @media ${media.tablet} {
    width: 90%;
    margin-top: 36px;
    padding-right: 0;
  }

  @media ${media.mobile} {
    margin-top: 18px;
    min-width: unset;
    width: 100%;
    padding-right: 0;
  }
`;

const SVGImage = styled.img`
  display: flex;
  width: 90%;
  height: 90%;

  @media ${media.desktop} {
    width: 90%;
    margin-left: 10%;
  }

  @media ${media.tablet} {
    width: 80%;
    margin-left: 15%;
    padding: 20px;
  }

  @media ${media.mobile} {
    padding: 10px;
    width: 100%;
  }
`;

const FooterContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #000;
  padding: 25px;
`;

const PoweredByText = styled.div`
  font-size: 1.2rem;
  color: #fff;
  margin-bottom: 20px;
  opacity: 0.5;
  font-family: 'Inter', sans-serif;
`;

const PoweredByImages = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  

  @media ${media.mobile} {
    flex-wrap: wrap;
    justify-content: center;
  }
`;

const PoweredByImage = styled.img`
  height: 60px;
  margin-right: 40px;
  margin-left: 40px;

  @media ${media.mobile} {
    margin-right: 10px;
    margin-bottom: 60px;
  }
`;

const PoweredByImageAnthropic = styled.img`
  max-width: 20%;
  max-height: 20%;
  margin-right: 40px;
  margin-left: 40px;
  align-items: center;

  @media ${media.mobile} {
    margin-right: 10px;
    margin-bottom: 60px;
    max-width: 60%;
    max-height: 60%;
  }
`;

const ServicesSection = styled.section`
  background: #000;
  padding: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 100px;
`;

const ServicesTitle = styled.h1`
  font-family: 'Inter', sans-serif;
  font-size: 2rem;
  max-width: 100%;
  text-align: center;
  margin: 0;
  line-height: 1.2;
  font-weight: 700;
  letter-spacing: normal;
  color: #fff;
  margin-bottom: 60px;
  opacity: 0.5;

  @media ${media.desktop} {
    font-size: 2rem;
  }

  @media ${media.laptop} {
    font-size: 2rem;
  }

  @media ${media.tablet} {
    text-align: center;
  }

  @media ${media.mobile} {
    font-size: 2.7rem;
  }
`;

const ServicesGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 50px;
  max-width: 800px;
  margin: 0 auto;

  @media (min-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
  }
`;

const ServiceItem = styled.div`
  background: #15171c;
  border-radius: 10px;
  padding: 20px;
  text-align: center;
  box-shadow: 0 4px 6px rgba(255, 255, 255, 0.1);
  border: 2px solid #174b42;

  @media (min-width: 1024px) {
    padding: 30px;
  }

  &:hover,
  &:focus {
    background-color: #174b42;
    border-color: #2c6b5f;
  }
`;

const ServiceIcon = styled.img`
  width: 60px;
  height: 60px;
  margin: 0 auto 20px;
`;

const ServiceTitle = styled.h3`
  font-family: 'Inter', sans-serif;
  font-weight: 600;
  color: #fff;
  font-size: 2rem;
  margin-bottom: 10px;

  @media (min-width: 1024px) {
    font-size: 2rem;
  }
`;

const ServiceDesc = styled.p`
  color: #ccc;
  font-size: 1.5rem;
  font-weight: 600;

  @media (min-width: 1024px) {
    font-size: 1.4rem;
  }
`;




const servicesData = [
  {
    icon: ChatIcon,
    title: 'AI Chat',
    description: 'Ask any question about your ordering and get instant answers.',
  },
  {
    icon: AnalyticsIcon,
    title: 'Item Analytics',
    description: 'Get Advanced Analytics and compare competitor pricing instantly.',
  },
  {
    icon: CartIcon,
    title: 'Cart Tracking',
    description: 'Manage multiple carts for limitless vendors all on one dashboard.',
  },
  {
    icon: OrderIcon,
    title: 'Predictive Ordering',
    description: 'Get optimized carts based on pricing, seasonal trends, etc.',
  },
  {
    icon: ChieldIcon,
    title: 'Theft Detection',
    description: 'Regularly identify theft and fraud in your pharmacy.',
  },
  {
    icon: TimeIcon,
    title: 'Reminders',
    description: 'Set and receive reminders for important tasks and events.',
  },
];

const App = () => {
  const chartFrames = [BigChart1, BigChart2, BigChart3, BigChart4, BigChart5, BigChart6, BigChart7];
  const [currentChartIndex, setCurrentChartIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentChartIndex((prevIndex) => (prevIndex + 1) % chartFrames.length);
    }, 400);

    return () => {
      clearInterval(interval);
    };
  }, [chartFrames.length]);

  let navigate = useNavigate();

  const handleButtonClick = () => {
    navigate('/signup'); // Path to the new page
  };

  return (
    <>
      <Container>
        <TextContainer>
          <Title>The Future of</Title>
          <Title>Pharmacy</Title>
          <Title>Management</Title>
          <Subtitle>
            Bringing Big-Box Solutions, Analytics, and Artificial Intelligence to Every Pharmacy
          </Subtitle>
          <ButtonContainer>
            <StartButton onClick={handleButtonClick}>Start Now</StartButton>
          </ButtonContainer>
        </TextContainer>
        <ChartContainer>
          <SVGImage src={chartFrames[currentChartIndex]} alt="Chart" />
        </ChartContainer>
      </Container>
      <FooterContainer>
        <PoweredByText>Powered by</PoweredByText>
        <PoweredByImages>
          <PoweredByImageAnthropic src={MistralSVG} alt="Mistral" />
          <PoweredByImage src={AwsSVG} alt="AWS" />
          <PoweredByImage src={PineconeSVG} alt="Pinecone" />
        </PoweredByImages>
      </FooterContainer>

      <ServicesSection>
        <ServicesTitle>Our Services</ServicesTitle>
        <ServicesGrid>
          {servicesData.map((service, index) => (
            <ServiceItem key={index}>
              <ServiceIcon src={service.icon} alt={service.title} />
              <ServiceTitle>{service.title}</ServiceTitle>
              <ServiceDesc>{service.description}</ServiceDesc>
            </ServiceItem>
          ))}
        </ServicesGrid>
      </ServicesSection>
      <Footer />
    </>
  );
};

export default App;
